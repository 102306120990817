import styled from "styled-components"

export type ButtonProps = {
  block?: boolean
  gray?: boolean
  disabled?: boolean
  wide?: boolean
}

const Button = styled.button<ButtonProps>`
  height: 42px;
  background: none;
  font-size: 16px;
  border-radius: 8px;
  transform: translateY(-5px);
  background: transparent;
  text-align: center;
  position: relative;
  border: none;
  transition-duration: 0.1s;
  padding: 0 ${props => (props.wide ? "2.5em" : "1em")};
  align-items: center;
  justify-content: center;
  outline: none;
  font-weight: bold;
  text-decoration: none;
  color: ${props => props.theme.color.black};
  display: ${props => (props.block ? "flex" : "inline-flex")};
  width: ${props => (props.block ? "100%" : "auto")};

  ${props =>
    props.disabled
      ? `opacity: 0.4;`
      : `

  cursor: pointer;
  &:hover {
    transform: translateY(-7px);

    &::before {
      transform: translateY(7px);
    }
  }

  &:active {
    transform: translateY(0px);

    &::before {
      transform: translateY(0px);
    }
  }
  
  `}
  &::before {
    content: "";
    display: block;
    position: absolute;
    transform: translateY(5px);
    border-radius: 8px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background-color: ${props =>
      props.gray
        ? props.theme.color.grayLight
        : props.theme.color.primaryLight};
    transition-duration: 0.1s;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    border: 2px solid #000;
    border-radius: 8px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }
`

export default Button
