import React, { FunctionComponent } from "react"
import "./ScrollContainer.scss"
import ScrollArea from "react-scrollbar/dist/no-css"

export type ScrollContainerProps = {
  rounded?: boolean
}

const ScrollContainer: FunctionComponent<ScrollContainerProps> = ({
  children,
  rounded,
}) => (
  <ScrollArea style={{ height: "100%", borderRadius: rounded ? "12px" : 0 }}>
    {children}
  </ScrollArea>
)

export default ScrollContainer
