import styled from "styled-components"
import React, { FC } from "react"
import ScrollContainer from "components/ScrollContainer"

const Wrapper = styled.div<{
  height?: string
  white?: boolean
  gray?: boolean
  margin?: boolean
  scrollable?: boolean
  noRadius?: boolean
  fill?: boolean
}>`
  background-color: ${props =>
    props.white
      ? "#fff"
      : props.gray
      ? props.theme.color.grayLight
      : props.theme.color.primaryLighter};
  ${props => props.white && `border: 2px solid ${props.theme.color.black};`}
  border-radius: ${props => (props.noRadius ? "0px" : "12px")};
  position: relative;
  overflow: ${props => (props.scrollable ? "hidden" : "visible")};
  margin: ${props => (props.margin ? "0 0 24px 0" : "0")};
  height: ${props => (props.height ? props.height : "auto")};
  ${props =>
    props.fill &&
    `
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `}
`

type Props = {
  height?: string
  white?: boolean
  gray?: boolean
  margin?: boolean
  scrollable?: boolean
  noRadius?: boolean
  fill?: boolean
}

const Widget: FC<Props> = ({
  height,
  white,
  gray,
  margin,
  children,
  scrollable,
  noRadius,
  fill,
}) => {
  return (
    <Wrapper
      height={height}
      white={white}
      gray={gray}
      margin={margin}
      scrollable={scrollable}
      noRadius={noRadius}
      fill={fill}
    >
      {scrollable ? <ScrollContainer>{children}</ScrollContainer> : children}
    </Wrapper>
  )
}

export default Widget
