import styled from "styled-components"

export type HeadingProps = {
  level?: 1 | 2 | 3
  strong?: boolean
  center?: boolean
  noMargin?: boolean
}

const Heading = styled.h1<HeadingProps>`
  line-height: 1.2;
  font-size: ${props => {
    switch (props.level) {
      case 1:
        return "32px"
      case 2:
        return "28px"
      case 3:
        return "24px"
      default:
        return "32px"
    }
  }};
  font-weight: ${props => (props.strong ? "bold" : "normal")};
  text-align: ${props => (props.center ? "center" : "left")};
  margin: ${props => {
    if (props.noMargin) {
      return "0"
    }

    switch (props.level) {
      case 1:
        return "12px 0"
      case 2:
        return "8px 0"
      case 3:
        return "6px 0"
      default:
        return "6px 0"
    }
  }};
`

export default Heading
