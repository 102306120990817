import styled from "styled-components"
import React, { useState } from "react"
import Heading from "components/Heading"
import { Link } from "gatsby"

const StyledImage = styled.div.attrs<{ src: string }>(({ src }) => ({
  style: {
    backgroundImage: `url(${src})`,
  },
}))<{ src: string; overlay?: boolean; link?: boolean; to?: string }>`
  padding-top: 75%;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  position: relative;
  margin: 8px;
  display: block;

  &::before {
    display: block;
    position: absolute;
    content: "";
    top: -8px;
    bottom: -8px;
    left: -8px;
    right: -8px;
    border: 1px solid #000;
    border-radius: 12px;
  }

  ${props =>
    props.overlay &&
    `&::after {
    position: absolute;
    content: "";
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${props.theme.color.primaryLight};
    opacity: 1;
    z-index: 1;
    mix-blend-mode: color;
  }`}
`

const StyledTitle = styled(Heading)`
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  z-index: 10;
  margin: 0;
`

const StyledOverlay = styled.div<{ isHovering?: boolean }>`
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 8px;
  overflow: hidden;
  background-color: ${props => props.theme.color.primaryLight};
  transition: opacity 0.2s;
  z-index: 2;
  opacity: ${props => (props.isHovering ? 0.7 : 0.85)};
`

const WidgetImage = ({
  src,
  overlay,
  title,
  link,
  to,
}: {
  src?: string
  overlay?: boolean
  title?: string
  link?: boolean
  to?: string
}) => {
  const [isHovering, setIsHovering] = useState(false)

  const handleMouseEnter = () => {
    setIsHovering(true)
  }

  const handleMouseLeave = () => {
    setIsHovering(false)
  }

  return (
    <StyledImage
      src={src || ""}
      as={link ? Link : "div"}
      to={link ? to : undefined}
      link={link}
      overlay={overlay}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {overlay && <StyledOverlay isHovering={isHovering} />}
      {title && <StyledTitle>{title}</StyledTitle>}
    </StyledImage>
  )
}

export default WidgetImage
